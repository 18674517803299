import {Grid, Stack, styled, Typography} from '@mui/material'
import React from 'react'

import {CardBox} from '../../containers'
import {Column} from '../DataTable.types'

export const StyledTitle = styled(Typography)({
  marginBottom: 2
})

export const StyledColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})
type ListTableProps<T> = {
  data: T[]
  columns: Column<T>[]
  onClick?: (item: T) => void
  getRowDataTestId?: (item: T) => string
}

const COLUMN_HEADER_SHIFT = 1

export const ListTable = <T,>({data, columns, onClick, getRowDataTestId}: ListTableProps<T>) => {
  return (
    <Stack gap={2}>
      {data.map((row, index) => (
        <CardBox
          px={1.5}
          onClick={onClick && (() => onClick(row))}
          key={index}
          data-test-id={getRowDataTestId?.(row)}
        >
          <Typography variant="h3" display="flex" alignItems="center" mb={2} gap={1}>
            {columns[0].customTemplate?.(row) || row[columns[0].key]}
          </Typography>
          <Grid container spacing={2}>
            {columns.slice(COLUMN_HEADER_SHIFT).map((column, columnIndex) => (
              <Grid key={column.key} item xs={4}>
                <StyledColumn key={column.key}>
                  <StyledTitle variant="caption">{column.label}</StyledTitle>
                  <Typography key={`${column.key}-value`}>
                    {column?.customTemplate?.(row) ||
                      row[columns[columnIndex + COLUMN_HEADER_SHIFT].key]}
                  </Typography>
                </StyledColumn>
              </Grid>
            ))}
          </Grid>
        </CardBox>
      ))}
    </Stack>
  )
}
